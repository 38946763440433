import React from "react";

import "./ExpensesFilter.css";

const ExpensesFilter = (props) => {
  //get prev data
  //foreach it into 4 different arrays, 1 array per year
  //set the bar max to the value of the highest year array
  //%the other arrays and add space to the bar accordingly.

  const dropdownChangeHandler = (event) => {
    props.onChangeFilter(event.target.value);
  };
  return (
    <div className="expenses-filter">
      <div className="expenses-filter__control">
        <label>Urgency by month</label>
        <select value={props.selected} onChange={dropdownChangeHandler}>
          {" "}
          {/* so the value for this select must be the option value */}
          <option value="2023">2023</option>
          <option value="2024">2024</option>
          <option value="2025">2025</option>
          <option value="2016">2026</option>
        </select>
      </div>
    </div>
  );
};

export default ExpensesFilter;
