import React, { useState } from "react";

import "./ExpenseForm.css";

// the 'event' is sent as an argument when you call the function e.g: <form onSubmit={submitHandler}> will pass data about the form i think

const ExpenseForm = (props) => {
  const [enteredTitle, setEnteredTitle] = useState("");
  const [enteredAmount, setEnteredAmount] = useState("");
  const [enteredDate, setEnteredDate] = useState("");

  const titleChangeHandler = (event) => {
    setEnteredTitle(event.target.value);
  };
  const amountChangeHandler = (event) => {
    setEnteredAmount(event.target.value);
  };
  const dateChangeHandler = (event) => {
    setEnteredDate(event.target.value);
  };

  const submitHandler = (event) => {
    event.preventDefault();

    const expenseData = {
      title: enteredTitle,
      amount: +enteredAmount,
      date: new Date(enteredDate),
    };
    console.log(expenseData);

    props.onSaveExpenseData(expenseData); // this passes some data to the function, which is then executed back in NewExpense
    setEnteredTitle("");
    setEnteredAmount("");
    setEnteredDate("");
  };

  //the following code handels the two forms, also for some reason it has to be in the order of(states and functions : variables : logic) or something like that
  const [showBox, setShowBox] = useState("");

  //changes form from real to simple
  const realFormHandler = () => {
    setShowBox("simple");
  };
  //changes form from simple to real
  const simpleFormHandler = () => {
    setShowBox("real");
  };

  let today = new Date().toLocaleDateString();
  let formToDisplay;
  let realForm = (
    <form onSubmit={submitHandler}>
      <div className="new-expense__controls">
        <div className="new-expense__control">
          <label>Goal</label>
          <input
            type="text"
            value={enteredTitle}
            onChange={titleChangeHandler}
          />
        </div>
        <div className="new-expense__control">
          <label>Urgency</label>
          <input
            list="urgency"
            // type="number"
            // min="0.01"
            // step="0.01"
            value={enteredAmount}
            onChange={amountChangeHandler}
          />
          <datalist id="urgency">
            <option value={1}>Low</option>
            <option value={2}>Medium</option>
            <option value={3}>High</option>
          </datalist>
        </div>
        <div className="new-expense__control">
          <label>Deadline</label>
          <input
            type="date"
            min={today}
            max="2025-1-1"
            value={enteredDate}
            onChange={dateChangeHandler}
          />
        </div>
      </div>
      <div className="new-expense__actions">
        <button type="submit">Add Goal</button>
        <button onClick={realFormHandler}>Cancel</button>
      </div>
    </form>
  );
  let simpleForm = (
    <div>
      <div className="new-expense__actions">
        <button onClick={simpleFormHandler}>New Goal</button>
      </div>
    </div>
  );

  //This decides which html bundle will be displayed based on the state value
  showBox === "simple"
    ? (formToDisplay = simpleForm)
    : (formToDisplay = realForm);

  return <div>{formToDisplay}</div>;
};

export default ExpenseForm;
