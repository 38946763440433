import React from "react";
import "./ExpensesList.css";
import ExpenseItem from "./ExpenseItem";

const ExpensesList = (props) => {
  let expensesContent = <p>No tasks found</p>;

  if (props.items.length === 0) {
    return <h2 className="expenses-list__fallback">{expensesContent}</h2>;
  }
  return (
    <ul className="expenses-list">
      {/*Mapping expense item data generated in App.js to the ExpenseItem format in ExpenseItem */}
      {props.items.map((expense) => (
        <ExpenseItem
          // this key is more react bs, it jsut needs it to do the lists properly
          key={expense.id}
          title={expense.title}
          amount={expense.amount}
          date={expense.date}
        />
      ))}
    </ul>
  );
};
export default ExpensesList;
