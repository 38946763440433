import React, { useState } from "react";

import ExpenseDate from "./ExpenseDate";
import Card from "../UI/Card";
import "./ExpenseItem.css";

const ExpenseItem = (props) => {
  // function clickHandler() {}
  const [isCompleted, setIsCompleted] = useState(false);
  let finalTitle = "";
  let markText = "";
  //complete uncomplete code
  // const completeHandler = () => {
  //   if (!title.toString().includes("(complete)")) {
  //     setTitle((prevTitle) => prevTitle + " (complete)");
  //     setCompleteText("Uncomplete");
  //   } else {
  //     setCompleteText("complete");
  //   }

  const setIsCompleteHandler = () => {
    setIsCompleted((prevState) => (!prevState));
  };

  if (isCompleted === true) {
    finalTitle = props.title + " (complete)";
    markText = "Incomplete"
  } else {
    finalTitle = props.title;
    markText = "Complete"

  }

  return (
    <Card className="expense-item">
      <div className="expense-container">

      
      <ExpenseDate date={props.date} />
      
      <div className="expense-item__description">
        <h2>{finalTitle}</h2>
        <div className="expense-item__price">Urgency: {props.amount}</div>
      </div>
      <button
        className="expense-item__mark-complete"
        onClick={setIsCompleteHandler}
      >
        Mark
        <br />
        {markText}
      </button>
      </div>
    </Card>
  );
};

export default ExpenseItem;
